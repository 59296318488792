import 'picturefill';
import 'lazysizes';
import 'lazysizes/plugins/print/ls.print';

// On lazyload
window.addEventListener('lazyloaded', (event) => {
  // Add loading finished class
  if (event.target.parentNode && event.target.parentNode.classList.contains('loading')) {
    event.target.parentNode.classList.add('loading--finished');
  }
});
